module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-native-web/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en","de","fr","it","el","ru","es"],"defaultLanguage":"en","path":"/home/rawsix/webapps/gloschli-web-staging/gatsby/locales","siteUrl":"https://staging.gloschli.ch","i18nextOptions":{"debug":true,"lowerCaseLng":true,"saveMissing":false,"interpolation":{"escapeValue":false},"keySeparator":".","nsSeparator":":"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Gloschli","short_name":"Gloschli","start_url":"/","background_color":"#ffffff","theme_color":"#a2466c","display":"standalone","icon":"src/Images/meta/Glubschi.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"9246956dc3e7f5822a97b9d6095194cb"},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"1","matomoUrl":"https://matomo.cousoft.ch/","siteUrl":"https://staging.gloschli.ch","disableCookies":true},
    }]
